<template>
  <div class="app_main">
    <div class="pc_content">
     <div class="px_container flex_col">
      <router-view></router-view>  

      <div class="dialog" v-show="dialogFlg"></div>
      <transition name="el-zoom-in-center">
        <div class="dialog_container" v-show="dialogFlg" >
          <div class="pop_close flex_center" @click="close"><svg t="1731048024625" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10340" width="16" height="16"><path d="M512 428.8l256-256c25.6-25.6 64-25.6 83.2 0 25.6 25.6 25.6 64 0 83.2l-256 256 256 256c25.6 25.6 25.6 64 0 83.2-25.6 25.6-64 25.6-83.2 0l-256-256-256 256c-25.6 25.6-64 25.6-83.2 0-25.6-25.6-25.6-64 0-83.2l256-256-256-256c-25.6-25.6-25.6-64 0-83.2 25.6-25.6 64-25.6 83.2 0l256 256z" fill="#000" p-id="10341"></path></svg></div>        
          <div class="dialog_header">{{ dialogContent.title }}</div>
          <div class="line"></div>
          <div class="dialog_content">
            <div class="dialog_message">
              I just found an amazing cheap recharge site with fast delivery in 10 minutes, come and join me!
              
              <span> {{ dialogContent.message }}</span>
             
            </div>
          </div>
          <div class="dialog_footer flex_center" @click="copy()">Copy link</div>
        </div>
      </transition>
      <div class="message" v-if="showMessage">{{ message }}</div>
     </div>
        
    
      
    </div>
    
  </div>
</template>

<script>
import { langMixins } from "../../mixins/langMixins";
export default {
  mixins:[langMixins],
  components:{
      

    },
  data(){
    return{
    message:'',
    showMessage:'',
    }
  },
  created(){
    this.languageAuto()
    this.$store.dispatch('getLanguage',{dictType:'t_goods_language'})
    this.$store.dispatch('getCurrency',{dictType:'t_currency'})
  },
  methods:{
    close(){
      this.$store.commit('setDialogFlg',false)
    },
    copy(){
      this.$clipboard(this.dialogContent.message)
      console.log('dialogContent',this.dialogContent.message)
      this.close()
      this.message = 'Replicating Success'
      this.showMessage = true
      setTimeout(()=>{
        this.showMessage = false
      },1500)
    },
  },
  computed:{
    dialogFlg(){return this.$store.state.dialogFlg},
    dialogContent(){ return this.$store.state.dialogContent}
  },

}
</script>

<style lang="scss" scoped>
.app_main{
  background: #161619;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei", sans-serif;

    .pc_content{
      width:400px;
      height: 92vh;
      max-height: 850px;
      overflow: hidden;
      border-radius: 2px;
      box-shadow: 0 8px 12px #121212;
      background:#fff;
      .px_container{
        width: 100%;
        height: 100%;
        position: relative;
        .message{
          padding:4px 6px;
          border-radius: 4px;
          background:  #5f5c5c99;
          color:#fff;
          position: absolute;
          margin: 0 auto;
          left:0;
          right:0;
          top:50%;
          max-width:160px;
          // left:calc(50% - 80px);
        }
        .dialog{
          position: absolute;
          left:0;
          right:0;
          top:0;
          bottom:0;
          background: rgba($color: #000000, $alpha: 0.8);
          z-index: 9999;
         
        }
        .dialog_container{

            position: absolute;
            top:35%;
            width:90%;
            margin-left:5%;
            // background:#fff;
            background: url(../../static/image/tc_bg.png);
            background-size: 100% 100%;
            font-size: 16px;
            border-radius: 10px;
            z-index: 99999;
            text-align: left;
            // transform: scale(0);
            // transition:transform 0.3s ease-in-out 0.5s;
            padding:12px 25px 27px;
            .pop_close{
              width: 18px;
              height:18px;
              // background:#000;
              border-radius: 50%;
              position: absolute;
              top:10px;
              right:10px;
            }
            .dialog_header{
              // padding-bottom:25px;
              padding-top:10px;
              font-weight: 700;
              color:#000000;
              text-align: center;
            }
            .line{
              width: 100%;
              height: 10px;
              background: url(../../static/image/line.png);
              background-size: 100% 100%;
              margin-bottom:20px;
            }
            .dialog_content{
              font-size: 14px;
             span{
              font-weight: 700;
              color:#000;
             }
              
            }
            .dialog_footer{
              color:#fff;
              height: 40px;
              background: linear-gradient( 360deg, #FF236C 0%, #FF4986 62%, #FF90B0 100%);
              box-shadow: inset 0px -3px 6px 1px #FF8A8B;
              width: 100%;
              border-radius: 20px;
              cursor: pointer;
              margin-top:20px;
            }
          }
      }
    }
}
</style>