
import Vue from 'vue'
import Vuex from 'vuex'
import cookies from '../utils/cookie'
import axiosApi from '../utils/api'
// import genshinImpact from '../assets/picture/Genshin Impact.png'
import pubg from '../assets/picture/PUBG MOBILE.png'
// import whiteout from '../assets/picture/Whiteout Survival.png'
// import brawl_stars from '../assets/picture/Brawl Stars.png'
// import clash_royale from '../assets/picture/Clash Royale.png'
// import legends from '../assets/picture/Mobile Legends Bang Bang.png'
import goddess from '../assets/picture/Goddess of Victory Nikke.png'
// import clash from '../assets/picture/Clash of Clans.png'
// import callofDuty from '../assets/picture/Call of Duty Mobile.png'
import freeFire from '../assets/picture/Free Fire.png'

// import toon from '../assets/picture/Toon Blast.png'
import roblox from '../assets/picture/Roblox.png'
// import nba from '../assets/picture/NBA 2K24.png'
// import town from '../assets/picture/Township.png'
// import go from '../assets/picture/MONOPOLY.png'

import arena from '../assets/picture/Arena Breakout.png'
import honor from '../assets/picture/Honor of Kings.png'
import alchemy from '../assets/picture/Alchemy Stars.png'
import metal from '../assets/picture/Metal Slug Awakening.png'
import tft from '../assets/picture/TFT Teamfight Tactics.png'
import dungeon from '../assets/picture/Dungeon & Fighter Mobile.png'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      dialogFlg:false,
      dialogContent:{},
      token:cookies.getToken(),
      langOptions:[],
      currencyOptions:[],
      currency:'',
      lang:'',
      defaultList:[
        // {categoryId:'3156375558713370',categoryId_act:'2838093181833339',categoryName_CH:'原神',categoryName:'Genshin Impact',src:genshinImpact,},
        {categoryId:'3140433533497385',categoryId_act:'2837902080954465',categoryName_CH:'PUBG绝地求生',categoryName:'PUBG MOBILE',src:pubg,},
        // {categoryId:'261532695717515271',categoryId_act:'299624698956623916',categoryName_CH:'寒霜启示录',categoryName:'Whiteout Survival',src:whiteout,},
        // {categoryId:'258626937709752390',categoryId_act:'16029026914041903',categoryName_CH:'荒野乱斗',categoryName:'Brawl Stars',src:brawl_stars,},
        // {categoryId:'258628749806047318',categoryId_act:'18202021793353829',categoryName_CH:'皇室战争',categoryName:'Clash Royale',src:clash_royale,},
        // {categoryId:'3160750788210731',categoryId_act:'',categoryName_CH:'无尽对决',categoryName:'Mobile Legends: Bang Bang',src:legends,},
        {categoryId:'62509959123648635',categoryId_act:'67326264711684131',categoryName_CH:'胜利女神',categoryName:'Goddess of Victory: Nikke',src:goddess,},
        // {categoryId:'258629997234995205',categoryId_act:'4766562704994428',categoryName_CH:'部落冲突',categoryName:'Clash of Clans',src:clash,},
        // {categoryId:'102468010732458043',categoryId_act:'17844468639060068',categoryName_CH:'使命召唤',categoryName:'Call of Duty: Mobile',src:callofDuty,},
        {categoryId:'326082764176007246',categoryId_act:'18331920017379355',categoryName_CH:'我要活下去',categoryName:'Free Fire',src:freeFire,},

        // {categoryId:'307600968435396730',categoryId_act:'',categoryName_CH:'卡通爆破',categoryName:'Toon Blast',src:toon,},
        {categoryId:'17965713565143082',categoryId_act:'',categoryName_CH:'罗布乐思',categoryName:'Roblox',src:roblox,},
        // {categoryId:'272799858591785078',categoryId_act:'',categoryName_CH:'NBA 2K24',categoryName:'NBA 2K24',src:nba,},
        // {categoryId:'307599887148019722',categoryId_act:'',categoryName_CH:'梦想城镇',categoryName:'Township',src:town,},
        // {categoryId:'246746372672237654',categoryId_act:'',categoryName_CH:'大富翁GO',categoryName:'MONOPOLY GO',src:go,},
        
        {categoryId:'147464881456955402',categoryId_act:'',categoryName_CH:'暗影突围',categoryName:'Arena Breakout',src:arena,},
        {categoryId:'246370387618328699',categoryId_act:'',categoryName_CH:'王者荣耀',categoryName:'Honor of Kings',src:honor,},
        {categoryId:'21319528855531577',categoryId_act:'',categoryName_CH:'白夜极光',categoryName:'Alchemy Stars',src:alchemy,},
        {categoryId:'212251611968204837',categoryId_act:'',categoryName_CH:'合金彈頭',categoryName:'Metal Slug Awakening',src:metal,},
        {categoryId:'18571288315330580',categoryId_act:'',categoryName_CH:'云顶之弈',categoryName:'TFT Teamfight Tactics',src:tft,},
        {categoryId:'18927037910843479',categoryId_act:'',categoryName_CH:'DNF地下城与勇士',categoryName:'Dungeon & Fighter Mobile',src:dungeon,},
      ]
    },
  
    mutations: {
      setDialogFlg(state,value){
        state.dialogFlg = value
      },
      setDialogContent(state,value){
        state.dialogContent = value
      },
      setLangOptions(state,value){
        state.langOptions = value
      },
      setCurrencyOptions(state,value){
        state.currencyOptions = value
      },
      setCurrency(state,value){
        state.currency = value
      },
      setLang(state,value){
        state.lang = value
      }
    },
    actions:{
      getLanguage({commit},{dictType}){
        axiosApi.getDictType({dictType}).then(res=>{
          commit('setLangOptions',res.data)
          res.data.map(v=>{
            if(v.dictSort==1){
              commit('setLang',v)
            }
          })
        })
      },
      getCurrency({commit},{dictType}){
        axiosApi.getDictType({dictType}).then(res=>{
          commit('setCurrencyOptions',res.data)
          res.data.map(v=>{
            if(v.dictSort == 1){
              commit('setCurrency',v)
            }
          })
         
        })
      },
    }
  })
