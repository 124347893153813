import Vue from 'vue' //引入Vue
import Router from 'vue-router' //引入vue-router
import appMain from '../view/index/appMian.vue'
Vue.use(Router)


//Vue全局使用Router
// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
// push
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
 
// replace
const originalReplace = Router.prototype.replace
Router.prototype.replace= function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


export default new Router({
  // mode:"history",
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return {
      x: 0,
      y: 0
    }
  },

  routes: [ //配置路由，这里是个数组
    // {
    //   path: '*', 
    //   redirect: '/appMian',
    //   component: appMain,
    // },
    { 
      path: '/', 
      redirect: '/index',
      component: appMain,
      children:[
        {
          path: '/index',
          name: 'index',
          component: () => import('@/view/index/index.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/checkout',
          name: 'checkout',
          component: () => import('@/view/index/checkout.vue'),
          meta:{
            rule:true
          }
        },
        {
          path: '/payment',
          name: 'payment',
          component: () => import('@/view/index/payment.vue'),
          meta:{
            rule:true
          }
        },
        {
          path: '/callbackpay',
          name: 'callbackpay',
          component: () => import('@/view/page/callbackpay.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/details',
          name: 'details',
          component: () => import('@/view/index/details.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/order',
          name: 'order',
          component: () => import('@/view/index/order.vue'),
          meta:{
            rule:true
          }
        },
        {
          path: '/updateOrder',
          name: 'updateOrder',
          component: () => import('@/view/index/updateOrder.vue'),
          meta:{
            rule:true
          }
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/view/index/register.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/view/index/login.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/personal',
          name: 'personal',
          component: () => import('@/view/index/personal.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/trems',
          name: 'trems',
          component: () => import('@/view/page/trems.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/invitation',
          name: 'invitation',
          component: () => import('@/view/index/invitation.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/aboutus',
          name: 'aboutus',
          component: () => import('@/view/page/aboutus.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/security',
          name: 'security',
          component: () => import('@/view/page/security.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/help',
          name: 'help',
          component: () => import('@/view/page/help.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/delivery',
          name: 'delivery',
          component: () => import('@/view/page/delivery.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/reliable',
          name: 'reliable',
          component: () => import('@/view/page/reliable.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/refund',
          name: 'refund',
          component: () => import('@/view/page/refund.vue'),
          meta:{
            rule:false
          }
        },
        {
          path: '/changePwd',
          name: 'changePwd',
          component: () => import('@/view/page/changePwd.vue'),
          meta:{
            rule:true
          }
        },
      ]
    },
    
    
    
  ]
})