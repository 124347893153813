import Cookies from 'js-cookie'
// 创建一个名称为name，对应值为value的cookie，由于没有设置失效时间，默认失效时间为该网站关闭时
let TokenKey = 'Token'
class cookies {
    static getToken() {
      let token = Cookies.get(TokenKey) 
      if(token)return token
      else return false
     
    }
  
    static setToken(token) {
      return Cookies.set(TokenKey, token)
    }
  
    static removeToken() {
      return Cookies.remove(TokenKey)
    }
    
    static getUserInfo() {
      let userInfo = Cookies.get('userInfo')
      if(userInfo)return JSON.parse(userInfo) 
      else return false
    
    }
    static setUserInfo(obj) {
      return Cookies.set('userInfo',JSON.stringify(obj))
    }
    static removeUserInfo() {
      return Cookies.remove('userInfo')
    }
  }  
export default cookies

