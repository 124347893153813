import request from './request'
class axiosApi{
    // 验证码
    static captchaImage(){
        return request({
            url:'/test_api/captchaImage',
            method:'get',
        })
    }
    // 注册
    static register({name,password,code,uuid,confirmPassword,inviteUserCode}){
        return request({
            url:'/test_api/userinfo/register',
            method:'post',
            data:{
                name,password,code,uuid,confirmPassword,inviteUserCode
            }
        })
    }
    // login
    static login({name,password,code,uuid}){
        return request({
            url:'/test_api/userinfo/login',
            method:'post',
            data:{
                name,password,code,uuid
            }
        })
    }
   // logout
   static logOut(){
    return request({
        url:'/test_api/userinfo/logout',
        method:'get',
       
    })
   }
    // changepwd
   static updatePwd({oldPwd,newPwd}){
        return request({
            url:'/test_api/userinfo/updatePwd',
            method:'post',
            data:{
                oldPwd,newPwd
            }
        })
    }
    // goodslist
    static getGoodsList({pageIndex,pageSize,language}){
        return request({
            url:'/test_api/goods/getGoodsList?pageIndex='+pageIndex+'&pageSize='+pageSize+'&language='+language,
            method:'get',
            
        })
    }
    // goodDetails
    static getGoodsDetail({goodsId,language}){
        return request({
            url:'/test_api/goods/getGoodsDetail?goodsId='+goodsId+'&language='+language,
            method:'get',
            
        })
    }
    // preOrderTemplate
    static preOrderTemplate({goodsId}){
        return request({
            url:'/test_api/order/preOrderTemplate?goodsId='+goodsId,
            method:'get',
            
        })
    }
    //收集信息
    static createOrder({goodsId,goodsNum,jsonObject}){
        return request({
            url:'/test_api/order/createOrder',
            method:'post',
            data:{
                goodsId,goodsNum,jsonObject
            }
        })
    }
    //提交销售订单交付收集
    static submitDelivery({array}){
        return request({
            url:'/test_api/order/submitDelivery',
            method:'post',
            data:{
                array,
            }
        })
    }
    // 上传图片
    static uploadChain({file}){
        return request({
            url:'/test_api/api/common/uploadChain',
            method:'post',
            data:{
                file
            }
        })
    }
   // 获取轮播图
    static getBannerList({type}){
        return request({
            url:'/test_api/api/common/getBannerList?type='+type,
            method:'get',
            data:{
                
            }
        })
    }
    // 订单列表
    static getOrderList({pageIndex,pageSize,status,orderStatus}){
        return request({
            url:'/test_api/order/getOrderList?pageIndex='+pageIndex+'&pageSize='+pageSize+'&status='+status+'&orderStatus='+orderStatus,
            method:'get',
            data:{
                
            }
        })
    }
      // 供应链获取图片
      static getPic({pic}){
        return request({
            url:'/test_api/api/common/getPic?pic='+pic,
            method:'get',
            data:{
                
            }
        })
    }
    // 获取轮播图
    static getBannerList({type}){
        return request({
            url:'/test_api/api/common/getBannerList?type='+type,
            method:'get',
            data:{
                
            }
        })
    }
    // 订单详情
    static getOrderDetail({id}){
        return request({
            url:'/test_api/order/getOrderDetail?id='+id,
            method:'get',
            data:{
                
            }
        })
    }
      // 订单数量
    static getOrderCount(){
        return request({
            url:'/test_api/order/getOrderCount',
            method:'get',
            data:{
                
            }
        })
    }

     // 提交销售订单交付收集   是否同意修改 -1拒绝 0同意

     static submitDelivery({isAgree,jsonObject,id,refundType,refundReasonId}){
        return request({
            url:'/test_api/order/submitDelivery',
            method:'post',
            data:{
                isAgree,jsonObject,id,refundType,refundReasonId
            }
        })
    }
    //语言和币种
    /* 
     t_goods_language
     t_currency
    */
    static getDictType({dictType}){
        return request({
            url:'/test_api/api/common/value?dictType='+dictType,
            method:'get',
            data:{
                
            }
        })
    }

    // zhifu
    static pay({orderNo,cardNo,cardSecurityCode,cardExpireMonth,cardExpireYear,billFirstName,billLastName,ip,billAddress,billCity,billState,billCountry,billZip,billPhone,email,shipFirstName,shipLastName,shipAddress,shipCity,shipState,shipCountry,shipZip,shipPhone}){
        return request({
            url:'/test_api/order/pay',
            method:'post',
            data:{
                orderNo,cardNo,cardSecurityCode,cardExpireMonth,cardExpireYear,
                billFirstName,billLastName,ip,billAddress,billCity,billState,billCountry,
                billZip,billPhone,email,shipFirstName,shipLastName,shipAddress,shipCity,shipState,shipCountry,shipZip,shipPhone
            }
        })
    }
    // 类别
    static getGoodsDetailList({categoryId}){
        return request({
            url:'/test_api/goods/getGoodsDetailList?categoryId='+categoryId,
            method:'get',
            data:{
                
            }
        })
    }
    // 用户信息
    static getUserinfo(){
        return request({
            url:'/test_api/userinfo/detail',
            method:'get',
            data:{
                
            }
        })
    }
}
export default  axiosApi